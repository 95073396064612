import { render, staticRenderFns } from "./User.vue?vue&type=template&id=5e65d5aa&lang=pug&"
import script from "./User.vue?vue&type=script&lang=js&"
export * from "./User.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VCheckbox,VImg,VLazy,VListItem,VListItemAction,VListItemActionText,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle})
